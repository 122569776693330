import React, { Component } from 'react';
import { withRouter} from 'react-router-dom';
import axios from '../../axios';


//δημιουργία cookie


class ConfirmMessage extends Component {


    componentDidMount(){

            axios.get(`/partner/confirmMessage/${this.props.match.params.issueId}`)
            .then(response => {
                alert('το αίτημα έγινε αποδεκτό')
                this.props.history.push('/dashboard');
        })
            .catch(error => {
                console.log(error);
            })
        }
    render() {

        return (
            <div style={{minHeight: '70vh', textAlign : 'center'}}>
                <h3>το αίτημα επιβεβαιώθηκε</h3>
            </div>
        )
        }   
    }

export default withRouter(ConfirmMessage);