import React, { Component } from 'react';
import Input from '../../Components/UI/input/input';
import Button from '../../Components/UI/Button/Button';
import Spinner from '../../Components/UI/Spinner/Spinner';
import axios from '../../axios';
import How from '../../Components/Marketing/How'
import { Cookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import './Main.css';


const cookies = new Cookies();

class Main extends Component {


state = {
    partner : {
        email: {elementType : 'input',
                elementConfig : {
                    type : 'email',
                    placeholder : 'Email',
                    required: true
                },
                value : ''
            },
        password: {elementType : 'input',
        elementConfig : {
            type : 'password',
            placeholder : 'Κωδικός',
            pattern : '.{6,}' ,
            required : true,
            title : 'Τουλάχιστον 6 ψηφία'
        },
        value : ''
    },
   

    },
    loading: false,
    error : false,
    errorMessage : null,
    terms : false,
    newsletter  : false
}

componentDidMount () {
    //if user is loged in replace page with dashboard
    if(cookies.get('token')) {
        this.props.history.replace('/dashboard');
    }
}



orderHandler = ( event ) => {
    event.preventDefault();
    this.setState( { loading: true } );
    const formData = {};
    for (let formElementIdentifier in this.state.partner) {
        formData[formElementIdentifier] = this.state.partner[formElementIdentifier].value;
    }
    const signup = {
        email: formData.email,
        password: formData.password,
        terms : this.state.terms,
        newsletter : this.state.newsletter,
        // mobile:formData.mobile
    }
    axios.post( '/partner/signup', signup )
        .then( response => {
            this.setState( { loading: false } );

            sessionStorage.setItem('partnerId', response.data.partnerId); 
            sessionStorage.setItem('partnerEmail', response.data.partnerEmail); 
            this.props.history.push('/shop')
        } )
        .catch( error => {
            this.setState( { loading: false, error : true,
                errorMessage : error.response.data.message});
        } );
}


inputChangedHandler = (event, inputIdentifier) => {
    const updatedOrderForm = {
        ...this.state.partner
    };
    const updatedFormElement = { 
        ...updatedOrderForm[inputIdentifier]
    };
    updatedFormElement.value = event.target.value;
    updatedOrderForm[inputIdentifier] = updatedFormElement;
    this.setState({partner: updatedOrderForm});

}

loginHandler  =() => {
    this.props.history.push('/login')
}


handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }


render () {
    let error = null;
   
    if(this.state.error) {
        error = <div className="alert alert-warning" role="alert" >{this.state.errorMessage}</div>
        setTimeout(() => {
          this.setState({error : false});
        }, 2000);
     }

 
    const formElementsArray = [];
    for (let key in this.state.partner) {
        formElementsArray.push({
            id: key,
            config: this.state.partner[key]
        });
    }
    let form = (
        <form onSubmit={this.orderHandler}>
            {formElementsArray.map(formElement => (
                <Input 
                    key={formElement.id}
                    elementType={formElement.config.elementType}
                    elementConfig={formElement.config.elementConfig}
                    value={formElement.config.value}
                    changed={(event) => this.inputChangedHandler(event, formElement.id)} />
            ))}
                    <div className="form-group">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="gridCheck1" name="terms" checked={this.state.terms}
                    onChange={this.handleInputChange} required/>
                        <label className="form-check-label" htmlFor="gridCheck1">
                        Με την εγγραφή αποδέχομαι τους <Link to="/terms">όρους χρήσης της υπηρεσίας</Link>
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="gridCheck2" name="newsletter"       checked={this.state.newsletter}
                    onChange={this.handleInputChange} />
                        <label className="form-check-label" htmlFor="gridCheck2">
                            Επιθυμώ να λαμβάνω νέα και προσφορές.
                        </label>
                    </div>
                    </div>
               <Button btnType="Success">Εγγραφή</Button>
               <p style={{marginTop : '20px', cursor :'pointer',fontSize: '12px' }} onClick={this.loginHandler}>Έχεις κάνει εγγραφή;<strong> Συνδέσου</strong></p>

        </form>
    );
    if ( this.state.loading ) {
        form = <Spinner />;
    }
    return (  
        <div>
        <section id="header">
            <div className="container">
                <div className = "row" >
                    <div className="col-lg-6 col-md-6 col-s-12 col-xs-12 intro">
                        <h1>Μια πλατφόρμα για<br /> τεχνικούς Η/Υ και κινητών</h1>
                        <h4>Καταχώρησε την επιχείρηση σου, βρες περισσότερους πελάτες και κέρδισε</h4>
                    </div>
                    <div className="col-lg-6 col-md-6 col-s-12 col-xs-12 signup form">
                        <h4>Ξεκίνα με Εγγραφή</h4>
                        {error}
                        {form}
                    </div>
                </div>
            </div>
        </section>
            <How />
        </div>

    );
}
}
export default Main;