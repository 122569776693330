import React, {Component} from 'react';
import axios from '../../axios';
import { Cookies } from 'react-cookie';
import styles from './Comments.module.css';

const cookies = new Cookies();

class Comments  extends Component {

    state = {
        comment : [],
        openTextArea : false,
        value : ''
    }
    componentDidMount () {
        axios.get(`/partner/comment/${cookies.get('partnerId')}`)
        .then(response => {
            console.log(response)
           const data = response.data.response.reverse();
           this.setState({comment : data});
        })
        .catch(error => {
            console.log(error);
        })
    }

    openTextAreaHundler = () => {
        this.setState(prevState => ({
            openTextArea: !prevState.openTextArea
          }));
    }

    handleChange = (event) => {
        this.setState({value: event.target.value});
      }
    
    handleSubmit = (event) => {
    const data = { partnerComment : this.state.value}
    axios.patch(`/partner/comment/${cookies.get('partnerId')}/${event.target.value}`, data)
    .then(response => {
        alert('η απάντηση αποθηκεύτηκε');
        this.openTextAreaHundler();
        axios.get(`/partner/comment/${cookies.get('partnerId')}`)
        .then(response => {
           const data = response.data.response.reverse();
           this.setState({comment : data});
        })
        .catch(error => {
            console.log(error);
        });
        })
    .catch(error => {
        alert('προβλημα κατα την αποθήκευση');
        })
    }

    render () {
        let openTextAreaEllement = null;
        if (this.state.openTextArea) {openTextAreaEllement =  <textarea className="form-control" rows="3" value={this.state.value} onChange={this.handleChange} placeholder="Γράψτε την απαντησή σας.."/> }
        let commentElement = null;
        if (this.state.comment.length !== 0) {
        commentElement = this.state.comment.map( data => {
            let prtnrComment = <p><strong>Απάντηση : </strong>{data.partnerComment}</p>;
            if(data.shop !== cookies.get('activeShop')) {return <p key={data._id}>Δεν υπάρχουν δεδομένα</p>}
            if(!data.partnerComment && !this.state.openTextArea) { prtnrComment =  <button type="button" className="btn btn-findfix" onClick={this.openTextAreaHundler}>Απάντηση</button> }
            if(!data.partnerComment && this.state.openTextArea) { prtnrComment =  <button type="button" className="btn btn-findfix" value={data._id} onClick={this.handleSubmit}>Αποθήκευση</button> }
            
            return (<div key={data._id} className={styles.comments}>
                    <p><strong>{data.user.first_name} {data.user.last_name.substring(0,1)}.</strong></p>
                    <p>{data.created_at.substring(8,10)}/{data.created_at.substring(5,7)}/{data.created_at.substring(0,4)}</p>
                    <div className={styles.starratingscss}>
                    {/* πάει το data.stars επι το font size */}
                    <div className={styles.starratingscsstop} style={{width : data.stars*13}}><span>★</span><span>★</span><span>★</span><span>★</span><span>★</span></div>
                    <div className={styles.starratingscssbottom}><span>★</span><span>★</span><span>★</span><span>★</span><span>★</span></div>
                    </div>
                    <p><strong>Σχόλιο : </strong>{data.userComment}</p>
                    {openTextAreaEllement}
                    {prtnrComment}
                    <hr />
                    </div>);
        });
        } else { commentElement = <p>Δεν υπάρχουν δεδομένα</p>}
  

        return (
            <div className="container">
                {commentElement}
            </div>
        );
    };
}

export default Comments;