import React, {Component} from 'react';
import axios from '../../axios';
import { Cookies } from 'react-cookie';

import LogoUpload from '../../Components/Dashboard/Logo';
import Services from '../../Components/Dashboard/Services';
import Category from '../../Components/Dashboard/Category'
import Textarea from '../../Components/Dashboard/Textarea';
// import Time from '../../Components/Dashboard/Time';

const cookies = new Cookies();

class Profile  extends Component {

  state = {
      logo : null,
      category : [],
      services : [],
      details : '',
      working_hours : '',
      partner : cookies.get('partnerId')
  }
  

componentDidMount() {
  if (!cookies.get('token') || cookies.get('token') === 'undefined') {
    this.props.history.replace('/login')
}

  axios.get(`/partner/descriptions/${this.state.partner}`)
  .then(result => {
    // console.log(result.data);
    this.setState({
      category : result.data.category,
      services : result.data.services,
      details : result.data.details,
      working_hours : result.data.working_hours,
    });
  })
  .catch (err => {
    console.log(err);
  })

  axios.get(`/shop/partner/${this.state.partner}`)
  .then(result => {
    this.setState({logo : result.data[0].logo});
  })
  .catch(err =>{
    console.log(err);
  })
}


  selectChangeHandler = (opt, target) => {
    let name = null;
    let data = [];
    for (let index = 0; index < opt.length; index++) {
      data.push(opt[index].value);
      
    }
    if (target.action === 'select-option') {
    name = target.option.name;
    this.setState({[name] : data});
    } 

    if(target.action === 'remove-value') {
    name = target.removedValue.name;
    this.setState({[name] : data});

  }

  //console.log(opt);
//console.log(this.state[name]);
}

  textAreaChangeHandler = (event) => {
    this.setState({details : event.target.value});
    
  }

   submitHandler = (event) => {
    //  console.log('name :',event.target.name);
     const storage = this.state;
     const name = event.target.name;
    //  console.log('state', storage[name]);
     const data = [{
      "propName" : name,
      "value" : storage[name]
     }];
    axios.patch(`/partner/descriptions/${this.state.partner}`, data)
    .then(response => {
      alert('Οι επιλογές σας αποθηκέυτηκαν');
    })
    .catch(err => {
      alert('σφαλμα κατα την αποθηκευση των επιλογών');
    })    
   }

  fileSelectHandler = (event) => {
    //παίνει το αρχείο που θέλει να ανεβάσει
    this.setState({logo : event.target.files[0]})
  }

  fileUploadHandler = (event) => {
    //το στέλνει στην βάση
    const fd = new FormData();
    fd.append('logo', this.state.logo, this.state.logo.name);
    axios.patch('/shop/logo/'+this.state.partner , fd, {
      onUploadProgress : progressEvent => {
        console.log(progressEvent);
      }
    })
    .then(result => {
      alert('Το λογοτυπο αποθηκέυτηκε');
    })
    .catch(err => {
      alert(`Το λογοτυπο δεν αποθηκέυτηκε. ${err.message}`);
    })
  
  }


    render() {
        return (
            <div className="container" style={{padding : '40px 0'}}>
                  <LogoUpload data={this.state.logo} fileSelect ={this.fileSelectHandler} fileUpload={this.fileUploadHandler} />
                <hr/>
                  <Category category={this.state.category} partner={this.state.partner} change={this.selectChangeHandler}  submit={this.submitHandler}/>
                <hr/>
                  <Services services={this.state.services} partner={this.state.partner} change={this.selectChangeHandler} submit={this.submitHandler}/>
                <hr />
                  {/* <Time /> */}
                {/* <hr /> */}
                  <Textarea details={this.state.details} changeText={this.textAreaChangeHandler} submit={this.submitHandler} />
            </div>
        );
    }
}

export default Profile;
