import React from 'react';


const olderRequests = (props) => {
    let button, serviceWay;
    if(!props.isClosed) { 
    switch (props.status) {
      case 1:
      button=<button type="button" className="btn btn-findfix" style={{minWidth : '220px'}} name={props.id} value="2" onClick={props.onstatus}>Παραλαβή</button>
      break;
      case 2:
      button=
              // (<div>
                <button type="button" className="btn btn-findfix" style={{minWidth : '220px'}} name={props.id} value="3" onClick={props.onstatus}>Έλεγχος</button>
          //       <input type="text"  value={props.partnerComment}  name="partnerComment" onChange={props.handleChange} placeholder="Τι θα επισκευάσετε"/>
          //       <input type="text"  value={props.price} name="price" onChange={props.handleChange} placeholder="Κόστος"/>
          // </div>)
       break;
      case 3:
      button=<button type="button" className="btn btn-findfix" style={{minWidth : '220px'}} name={props.id} value="4" onClick={props.onstatus}>Αναμονή Ανταλλακτικού</button>
      break;
      case 4:
      button=<button type="button" className="btn btn-findfix" style={{minWidth : '220px'}} name={props.id} value="5" onClick={props.onstatus}>Επισκευή</button>      
      break;
      case 5:
      button=<button type="button" className="btn btn-findfix" style={{minWidth : '220px'}} name={props.id}  onClick={props.onclose}>Ολοκλήρωση επισκεύης</button>
      break;
      default:
      button = <button type="button" className="btn btn-light">η επισκευή <br />έχει ολοκληρωθέι</button>
      break;
    }
  } else {      button = <button type="button" className="btn btn-light">η επισκευή έχει ολοκληρωθέι</button>
}
if (props.serviceWay === null) { serviceWay = <p>ο Πελάτης δεν έχει επιλέξει τρόπο εξυπηρέτησης <br />Επικοινωνήστε μαζί του για περισσότερα</p>}
else {serviceWay = <p>{ props.serviceWay }</p>}
    return(
      <tr>
      <th scope="row">{props.number}</th>
        <td>
          <p>{props.date.substring(8,10)}/{props.date.substring(5,7)}/{props.date.substring(0,4)}</p>
          <p>Eπισκευή {props.category} {props.brand} {props.model}</p>
          <p>{props.issue}</p>
          <p>{props.comment}</p>
          {serviceWay}
        </td>
        <td>
          <p>{props.first_name} {props.last_name}</p>
          <p>{props.route} {props.street_number} {props.locality} {props.postal_code}</p>
          <p><strong>Τηλέφωνο : </strong>{props.phone}</p>
        </td>
        <td>
          {button}
        </td>
    </tr>
    );
}

export default olderRequests;