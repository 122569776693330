import React, { Component } from 'react';
import { Cookies } from 'react-cookie';

import Spinner from '../../Components/UI/Spinner/Spinner';
import './Dashboard.css';
import Socket from '../../Components/Dashboard/Socket';
// import SideBar from '../../Components/Dashboard/Sidebar';
import OlderRequests from '../../Components/Dashboard/OlderRequests';
import ClosedRequests from '../../Components/Dashboard/ClosedRequests';
import axios from '../../axios';

//δημιουργία cookie
const cookies = new Cookies();

//δημιουργία ioclient
const
io = require("socket.io-client"),
// ioClient = io.connect("http://localhost:8000");
// ioClient = io.connect("https://findfix-socket.herokuapp.com/" );
ioClient = io.connect("https://socket.findfix.gr:8000/",{ transports: [ 'websocket' ]});

class Enquiries extends Component {

//state
state = {
  partner:cookies.get('partnerId'),
  issue :'',
  enquiry:null,
  category:'',
  brand:'',
  model : '',
  comment : '',
  user: null,
  message : null,
  userData : {},
  userAddress : {},
  loader : false,
  error : false,
  errorMessage : '',
  olderRequests :[],
  closedRequests : [],
  redirect : false,
  shop : [],
  status : '',
  localitySelect : null,
  partnerComment : '',
  price : ''
}

componentDidMount () {
//ελέγχει αν ειναι συνδεδεμένος και εχω βάλει undefine σε περίπτωση που πάει απο κάπου αλλου!
    if (!cookies.get('token') || cookies.get('token') === 'undefined') {
        this.props.history.replace('/login')
    }
   
    //παίρνει τα στοιχεία καταστήματος
    axios.get(`/shop/partner/${this.state.partner}`)
    .then(result => {
      // console.log(result.data[0]._id);
      if (!cookies.get('activeShop') || cookies.get('activeShop')=== 'undefined') {
        cookies.set('activeShop', result.data[0]._id, {path : '/', maxAge : 3153600000 });
        this.setState({shop : result.data, localitySelect : result.data[0]._id});
      } else {
        this.setState({shop : result.data, localitySelect :  cookies.get('activeShop')});
      }

      //open enquirys
      axios.get(`/partner/enquirys/${cookies.get('partnerId')}/false`)
      .then((olderRequests) => {
        const data = [...olderRequests.data.result].reverse();
        this.setState({ olderRequests: data});
       //closed enquirys
           axios.get(`/partner/enquirys/${cookies.get('partnerId')}/true`)
           .then((closedRequests) => {
             const data = [...closedRequests.data.result].reverse();
             this.setState({ closedRequests: data}); 
                axios.patch(`/shop/active/${cookies.get('activeShop')}`, {socketId :ioClient.id})
                .then((active) =>{console.log('Ο χρήστης είναι ενεργός');})
                .catch((err) => {console.log(err);});

                //////socket
                ioClient.on([cookies.get('activeShop')], data => {
                  console.log('irthe to mynhma',data);
                  this.setState({
                    partner:data.partner,
                    issue :data.issue,
                    category:data.category,
                    brand:data.brand,
                    user: data.user,
                    model: data.model,
                    comment : data.comment,
                    redirect : true,
                    loader : true,
                    enquiry : true
                  });
                
                 //βρίσκει τον χρήστη στην βάση
                 setTimeout(() => {
                  // console.log('user :', this.state.user)
                  axios.get('/user/'+this.state.user)
                  .then((result) => {
                    this.setState({ userData: result.data});
                    // console.log(this.state.userData);
                    axios.get(`/user/address/${this.state.user}`) 
                    .then((addressResult) => {
                      this.setState({userAddress : addressResult.data});
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                  }).catch((err) => {
                    console.log(err);
                  });
                  this.setState({loader : false});   
                 }, 2000);
                
                
                 //παίζει ήχο
                
                 var x = document.getElementById("myAudio");
                 x.play();
                });
                
           })
           .catch((err) => {
           console.log(err);
           // this.setState({ olderRequests: null});
           });
       })
       .catch((err) => {
       console.log(err);
       // this.setState({ olderRequests: null});
       });

    })
    .catch(err =>{
      console.log(err);
    })
}


handleInputChange = (event) => {
  const target = event.target;
  const value = target.type === 'checkbox' ? target.checked : target.value;
  const name = target.name;

  this.setState({
    [name]: value
  });
}


//κλεινω το αίτημα
closeIssueHandler = (event) => {

  const newOlderRequests = this.state.olderRequests

    for(var i=0 ; i< newOlderRequests.length; i++) {
        if(newOlderRequests[i]._id === event.target.name) {
           axios.patch(`/partner/enquirys/${newOlderRequests[i]._id}`)
         .then(result => {
              axios.get(`/partner/enquirys/${cookies.get('partnerId')}/true`)
              .then((result) => {
                
                const data = [...result.data.result].reverse();
                // console.log("fernei ta kleista")
                this.setState({ closedRequests: data});
              }).catch((err) => {
              console.log(err);
              // this.setState({ olderRequests: null});
        });
         })
         .catch(error  => {
           console.log(error);
         });

         newOlderRequests.splice(i,1);
        }    
    }
   this.setState({olderRequests : newOlderRequests}); 


}


//status επισκευής
statusHandler = (event) => {

  const newOlderRequests = this.state.olderRequests

    for(var i=0 ; i< newOlderRequests.length; i++) {
        if(newOlderRequests[i]._id === event.target.name) {
         newOlderRequests[i].status = Number(event.target.value);
         let data = {status : Number(event.target.value)}
         axios.patch(`/partner/enquirysStatus/${newOlderRequests[i]._id}`, data)
         .then(result => {
           console.log('issue status changed');
           alert('Επιτυχία! Ο πελάτης ενημερώθηκε')
         })
         .catch(error  => {
           console.log(error);
         });
        }    
    }
   this.setState({olderRequests : newOlderRequests}); 
}



//κάνω αποδοχή
clickHandler =(event) => {

//το στέλνω στον χρήστη
  ioClient.emit('messageFromPartner', {
    message : 'O Τεχνικος είναι καθοδών',
    issue :this.state.issue,
    category:this.state.category,
    brand:this.state.brand,
    model: this.state.model,
    comment: this.state.comment,
    user : this.state.user,
    partner : this.state.partner,
  });


  //σταματάω τον ήχο
  var x = document.getElementById("myAudio");
  x.pause();
  
  this.setState({enquiry: false});
}


//απόρριψη
denyHandler =(event) => {

  //το στέλνω στον χρήστη
    ioClient.emit('messageFromPartner', 
    {
      issue : null,
      user : this.state.user,
      partner : this.state.partner,
    });
  
  
    //σταματάω τον ήχο
    var x = document.getElementById("myAudio");
    x.pause();
    
    this.setState({enquiry: false});
  }

updateEnquirys = () => {
  axios.get(`/partner/enquirys/${cookies.get('partnerId')}/false`)
  .then((result) => {
    const data = [...result.data.result].reverse();
    this.setState({ olderRequests: data });
 }).catch((err) => {
   console.log(err);
  //  this.setState({ olderRequests: null});
 });

 setTimeout(() => {
   this.setState({enquiry : null});
 }, 600);
}


localitySelectHandler =(event) => {
  event.persist();
  // console.log(`state1 ${this.state.localitySelect}`)
  // console.log(`event ${event.target.value}`);
  ioClient.removeAllListeners();
  ioClient.on(event.target.value, data => {
    console.log('irthe to mynhma',data);
    this.setState({
      partner:data.partner,
      issue :data.issue,
      category:data.category,
      brand:data.brand,
      user: data.user,
      model: data.model,
      comment : data.comment,
      redirect : true,
      loader : true,
      enquiry : true
    });
  
   //βρίσκει τον χρήστη στην βάση
   setTimeout(() => {
    // console.log('user :', this.state.user)
    axios.get('/user/'+this.state.user)
    .then((result) => {
      this.setState({ userData: result.data});
      // console.log(this.state.userData);
      axios.get(`/user/address/${this.state.user}`) 
      .then((addressResult) => {
        this.setState({userAddress : addressResult.data});
      })
      .catch((err) => {
        console.log(err);
      });
    }).catch((err) => {
      console.log(err);
    });
    this.setState({loader : false});   
   }, 2000);
  
  
   //παίζει ήχο
  
   var x = document.getElementById("myAudio");
   x.play();
  });
 
  
  axios.patch(`/shop/deactive/${this.state.localitySelect}`)
  .then((active) =>{
    console.log(`Ο χρήστης ${this.state.localitySelect} είναι ανενεργός`);
    this.setState({localitySelect : event.target.value})})
  .catch((err) => {console.log(err);});

  
  
  cookies.set('activeShop', event.target.value, {path : '/', maxAge : 3153600000 })
  // this.setState({localitySelect: event.target.value});
  axios.patch(`/shop/active/${cookies.get('activeShop')}`, {socketId :ioClient.id})
  .then((active) =>{console.log(`Ο χρήστης ${cookies.get('activeShop')} είναι ενεργός`);})
  .catch((err) => {console.log(err);});
  // console.log(`cookie ${cookies.get('activeShop')}`)
}
render () {

  //olderRequests 
  // console.log(ioClient)
  // console.log(`state2 ${this.state.localitySelect}`)
  // console.log(this.state.olderRequests);
  if (this.state.enquiry === false) { this.updateEnquirys();}
  let olderrequests = null;
  if (this.state.olderRequests.length !== 0) {
  olderrequests = this.state.olderRequests.map( data => {
    if(data.shop === cookies.get('activeShop')) {
    return (<OlderRequests key={data._id} 
                          date={data.created_at}
                          first_name={data.user_device.user.first_name} 
                          last_name={data.user_device.user.last_name} 
                          phone={data.user_device.user.phone}
                          category={data.user_device.category}
                          brand={data.user_device.brand}
                          model={data.user_device.model}
                          issue={data.issue}
                          serviceWay = {data.serviceWay}
                          id = {data._id}
                          isClosed = {data.closed}
                          status = {data.status}
                          number = {data.number}
                          route = {data.user_device.user.user_address[0].route}
                          street_number = {data.user_device.user.user_address[0].street_number}
                          locality = {data.user_device.user.user_address[0].locality}
                          postal_code = {data.user_device.user.user_address[0].postal_code}
                          onclose={this.closeIssueHandler} 
                          onstatus={this.statusHandler} 
                          // partnerComment={this.state.partnerComment} //στον ελεγχο
                          // price={this.state.price}
                          handleChange={this.handleInputChange} 
                          />);
    } else { return null}
  });
} else { olderrequests = <tr><td></td><td></td><td>Δεν υπάρχουν δεδομένα</td><td></td></tr>}
  

//closed requests
let closedrequests = null;
// console.log(this.state.closedRequests);
  if (this.state.closedRequests.length !== 0) {
    closedrequests = this.state.closedRequests.map( data => {
      if(data.shop === cookies.get('activeShop')) {
    return (<ClosedRequests key={data._id} 
                          date={data.created_at}
                          first_name={data.user_device.user.first_name} 
                          last_name={data.user_device.user.last_name} 
                          route = {data.user_device.user.user_address[0].route}
                          street_number = {data.user_device.user.user_address[0].street_number}
                          locality = {data.user_device.user.user_address[0].locality}
                          postal_code = {data.user_device.user.user_address[0].postal_code}
                          phone={data.user_device.user.phone}
                          category={data.user_device.category}
                          brand={data.user_device.brand}
                          model={data.user_device.model}
                          issue={data.issue}
                          number = {data.number}
                          comment = {data.comment}
                          date_closed = {data.date_closed}

                          />);
                        } else { return null}
  });
} else { closedrequests =  <tr><td></td><td></td><td>Δεν υπάρχουν δεδομένα</td><td></td></tr>}
  
  //error hundling
  let error = null;
    if(this.state.error) {
        error = <div className="alert alert-warning" role="alert" >{this.state.errorMessage}</div>
        setTimeout(() => {
          this.setState({error : false});
        }, 2000);
     }

  //redirect if not on this page
  // let redirectElement=null;
  // if(this.props.location.pathname !== "/dashboard" && this.state.redirect) { redirectElement = (<Redirect to='/'/>);};
  
  
  let element=<p>Αναμονή αιτημάτων..</p>;
  
  if (this.state.enquiry) {
   let userDataState = this.state.userData;
   let userAddressState = this.state.userAddress;
   
  //το element το κάνει socket
   element = (
  <Socket
    enquiry ={this.state.enquiry}
    user={userDataState} 
    address={userAddressState} 
    category = {this.state.category} 
    brand={this.state.brand} 
    model={this.state.model} 
    issue = {this.state.issue} 
    comment = {this.state.comment}
    denied = {this.denyHandler}
    clicked ={this.clickHandler} />
  );
  }

  //spinner
  if(this.state.loader) {
    element = <Spinner />;
  }
    

  //shop names 
  let shop_name, locality_select, selected = null;
  if (this.state.shop.length > 0) {
    shop_name = this.state.shop[0].shop_name;
    let value =   Object.values(this.state.shop).map(data => {
      if (cookies.get('activeShop')=== data._id) {selected = true} else {selected = false}
      return (<option key={data._id} value={data._id} selected={selected}>{data.route}</option>)
   })
    locality_select = (
      <div className="form-group row">
        <label htmlFor="localitySelect" className="col-4 col-form-label">Κατάστημα</label>
        <div className="col-8">
          <select className="form-control" id="localitySelect" onChange={this.localitySelectHandler}>
          {value}
          </select>
        </div>
      </div>
    );
  }
    return (
      <div>
        {/* ο ήχος */}
        <audio id="myAudio">
          <source src="./sounds/ring.mp3" type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
        {/* {redirectElement} */}
        {error}
          {/* εδω ξεκινά η σελίδα. έβαλα τα routes εδω */}
            <div style={{minHeight : '80vh'}}>
                <div className="requests">
                        <div className="container">
                        {/* {JSON.stringify(this.state.shop[0])} */}
                          <div className="row" style={{marginTop : '40px'}}>
                            <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                              <h3>{shop_name}</h3>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 locality">
                              {locality_select} 
                            </div>
                          </div>
                        <div className="request-card">
                          <h5>Εισερχόμενα μηνύματα</h5>
                            {element}       
                        </div>
                        <div className="request-card">
                          <h5>Επισκευές</h5>
                          <div className="table-responsive">
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Αίτημα</th>
                                    <th scope="col">Πελάτης</th>
                                    <th scope="col">Ενημέρωση κατάστασης</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  { olderrequests }
                                </tbody>
                              </table>
                            </div>
                        </div>
                        <div className="request-card">
                          <h5>Ολοκληρωμένες Επισκευές</h5>
                              <div className="table-responsive">
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Αίτημα</th>
                                    <th scope="col">Πελάτης</th>
                                    <th scope="col">Κατάσταση</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  { closedrequests }
                                </tbody>
                              </table>
                            </div>
                        </div>
                      </div>
                        
                </div>
              
              </div>
          </div> 
    );
  }
}

  export default Enquiries;