import React from 'react';

const logoUpload = (props) => {
    let logo;
    if (props.data) {
        // https://boiling-stream-41133.herokuapp.com
        // logo = <img src={`https://boiling-stream-41133.herokuapp.com/${props.data}`} alt="" width="100px"/>
        //server
        logo = <img src={`https://api.findfix.gr:4000/${props.data}`} alt="" width="100px"/>
        //local
        // logo = <img src={`http://localhost:4000/${props.data}`} alt="" width="100px"/>
    }

    return (
    <div className="container">
        <h3>Το λογότυπο ή φωτογραφία της επιχείρησης</h3>
            { logo }
            <div className="form-group">
                <input type="file" className="form-control"  onChange={props.fileSelect}/>
            </div> 
        <p>μεχρι 5MB σε μορφή .png ή .jpg</p>
        <button className="btn btn-info" onClick={props.fileUpload} >Αποθήκευση</button>
    </div> 
 
);
    }
export default logoUpload;