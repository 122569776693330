import React from 'react'

import styles from './MainMenu.module.css';
import MenuItems  from '../MenuItems/MenuItems';

const mainmenu = (props) => {
    return (
            <ul className="navbar-nav"> 
                <li class="nav-item">
                    <a class="nav-link" href="https://www.findfix.gr/">Επιστροφή στο <strong>FindFix.gr</strong></a>
                </li>
                <div className={styles.mainmenuLink}>
                    {/* <MenuItems name='FindFix.gr' href="https://www.findfix.gr/" /> */}
                    <MenuItems name='ΣΥΝΔΕΣΗ' clicked={() => props.modalShow('ΣΥΝΔΕΣΗ')} />
                </div>
                {/* <MenuItems name='ΕΓΓΡΑΦΗ' clicked={() => props.modalShow('ΕΓΓΡΑΦΗ')} /> */}
            </ul>
    );
}

export default mainmenu;